<template>
  <!-- evaluation-h5 - 测评管理 - 测评审核 - 运营 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">测评管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">测评审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="测评问卷名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em">测评问卷名称:</span>
              <el-input v-model="searchForm.paperName" type="text" clearable size="small" placeholder="请输入测评问卷名称" />
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select clearable size="small" v-model="searchForm.paperAuditState" placeholder="请选择审核状态">
                <el-option v-for="item in auditStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="机构名称" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model="searchForm.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" stripe :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="测评问卷名称" align="left" show-overflow-tooltip prop="paperName" min-width="150" />
              <el-table-column label="机构名称" align="left" show-overflow-tooltip prop="compName" min-width="150" />
              <el-table-column label="分类" align="left" show-overflow-tooltip prop="evaluateCategory" min-width="100">
                <template slot-scope="scope">{{ $setDictionary("HR_EVALUATE_CATEGORY",
                  scope.row.evaluateCategory) }}</template>
              </el-table-column>
              <el-table-column label="来源" align="left" show-overflow-tooltip prop="paperSource" min-width="150" />
              <el-table-column label="售卖价格（￥）" align="left" show-overflow-tooltip prop="sellingPrice" min-width="100" />
              <el-table-column label="审核状态" align="left" show-overflow-tooltip prop="paperAuditState" min-width="100">
                <template slot-scope="scope">{{ $setDictionary("PAPER_AUDIT_STATE",
                  scope.row.paperAuditState) }}</template>
              </el-table-column>
              <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTimeStr"
                min-width="120">
                <template slot-scope="scope">{{ scope.row.createTimeStr | momentDate }}</template>
              </el-table-column>
              <el-table-column label="备注" align="left" show-overflow-tooltip prop="remark"
                min-width="150" />
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <template slot-scope="scope" class="flexcc">
                  <el-button style="padding: 0px 5px" type="text" size="mini"
                    @click="clickRejectEvaluation(scope.row, 1)" :disabled="scope.row.paperAuditState == '10' || scope.row.paperAuditState == '30'">通过</el-button>
                  <el-button style="padding: 0px 5px" type="text" size="mini"
                    @click="clickRejectEvaluation(scope.row, 2)" :disabled="scope.row.paperAuditState == '10' || scope.row.paperAuditState == '40'">驳回</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 驳回  弹窗 -->
    <el-dialog title="驳回原因" :visible.sync="rejectEvaluationData.dialogVisible" width="500px"
      :before-close="noPassExamineClose">
      <el-form :model="rejectEvaluationData" :rules="rejectEvaluationDataRules" ref="rejectEvaluationData"
        label-width="100px" size="small" class="demo-ruleForm">
        <el-form-item label="驳回原因" prop="remark">
          <el-input type="textarea" v-model="rejectEvaluationData.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="noPassExamineClose">取 消</el-button>
        <el-button size="small" class="bgc-bv" @click="passExamine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
let editor = {};
export default {
  name: "evaluationManagement_evaluationPriceExamine",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        paperName: "", // 测评问卷名称
        paperAuditState: "", // 审核状态
        compId: "", // 机构id
      },
      // 审核状态 - 检索数据
      auditStatusList: [],
      // 机构名称 - 检索数据
      CompanyList: [],
      // 驳回 - 弹框数据
      rejectEvaluationData: {
        dialogVisible: false, // 弹框状态
        paperId: "", // 调查问卷id
        remark: "", // 驳回原因
      },
      // 驳回 - 弹框数据校验
      rejectEvaluationDataRules: {
        remark: [
          { required: true, message: '请填写驳回原因', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {},
  mounted() { },
  created() {
    this.getDictionary();
  },
  methods: {
    // 机构名称 - 检索用
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取字典
    getDictionary() {
      // 审核状态
      let auditStatus = this.$setDictionary("PAPER_AUDIT_STATE", "list");
      for (const key in auditStatus) {
        this.auditStatusList.push({
          value: key,
          label: auditStatus[key],
        });
      }
    },
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchForm.paperName) {
        params.paperName = this.searchForm.paperName
      }
      if (this.searchForm.paperAuditState) {
        params.paperAuditState = this.searchForm.paperAuditState
      }
      if (this.searchForm.compId) {
        params.compId = this.searchForm.compId
      }
      this.doFetch({
        url: "/biz/hr/evaluate/paper/auditPageList",
        params,
        pageNum,
      }, true, 6);
      setTimeout(() => {
        console.log(this.tableData)
      }, 1000)
    },
    // 驳回type=1通过；2-驳回
    clickRejectEvaluation(row, type) {
      if (type == 1) {
        this.$post('/biz/hr/evaluate/paper/audit', {
          paperId: row.paperId,
          auditPass: true
        }, 3000, true, 6)
          .then((res) => {
            if (res.status == "0") {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.getData(this.pageNum);
            }
          })
      } else {
        this.rejectEvaluationData.paperId = row.paperId;
        this.rejectEvaluationData.dialogVisible = true;
      }
    },
    // 驳回 - 提交
    passExamine() {
      this.$refs['rejectEvaluationData'].validate((valid) => {
        if (valid) {
          this.$post('/biz/hr/evaluate/paper/audit', {
            paperId: this.rejectEvaluationData.paperId,
            auditPass: false,
            remark: this.rejectEvaluationData.remark
          }, 3000, true, 6)
            .then((res) => {
              if (res.status == "0") {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.noPassExamineClose();
                this.getData(this.pageNum);
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 驳回 - 关闭
    noPassExamineClose() {
      this.rejectEvaluationData = this.$options.data().rejectEvaluationData;
      this.$refs["rejectEvaluationData"].clearValidate();
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped></style>
